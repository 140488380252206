<template>
  <div v-if="isDataLoaded">
    <v-tabs v-model="tab" color="secondary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
    </v-tabs>
    <v-divider />
    <!-- Filters -->
    <posts-filter
      :show-select-teams="!isGuild"
      :show-publish-button="tab === 0"
      @updateSearch="params => filters = params"
      @togglePublish="publish = !publish"
      @resetDate="filters.date = null"
    />
    <!-- posts Cards -->
    <div class="pa-6">
      <posts-card
        v-for="post in filteredPosts"
        :id="post.id"
        :key="post.id"
        :organization-id="organizationId"
        :project-id="projectId"
        :data="post"
        :publish="publish"
        @onPublishChecked="checkPublish"
        @onPublish="publishPost"
        @onDuplicate="duplicatePost"
        @onArchive="archivePost"
        @onUnarchive="unarchivePost"
        @onDelete="deletePost"
      />
    </div>
    <!-- Button Create -->
    <v-btn bottom color="primary" fab fixed right
           @click="$router.push({ name: 'create-post' })"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <!-- Publish Footer -->
    <v-footer v-if="publish" bottom fixed padless color="white">
      <v-card style=" width: 100%;
                      min-height: 100px;"
              class="d-flex align-center justify-center"
      >
        <v-card-actions>
          <v-btn type="submit" class="mx-3" depressed @click.prevent="publish = false" v-text="$t('common.cancel')" />
          <v-btn color="primary" type="submit" class="mx-3" dark depressed @click.prevent="publishSelectedPosts" v-text="$t('common.publish')" />
        </v-card-actions>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { FieldValue } from '@/services/firebase'

export default {
  name: 'ProjectPosts',
  components: {
    PostsCard: () => import('@/modules/post/PostsCard'),
    PostsFilter: () => import('@/modules/post/PostsFilter'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      tab: null,
      publish: false,
      filters: null,
      postsBackup: [],
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({ posts: 'post/posts' }),
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters('project', ['isGuild']),
    tabs() {
      return [
        {
          id: 'active',
          name: this.$t('filters.active'),
        },
        {
          id: 'archived',
          name: this.$t('filters.archived'),
        },
      ]
    },
    postsUpdated: {
      get() {
        return this.postsBackup
      },
      set(post) {
        this.postsBackup = this.posts.map(backup => backup.id === post.id ? { ...post, publishedAt: FieldValue.serverTimestamp() } : backup)
      },
    },
    filteredPosts({ postsUpdated, tab, filters, orderByCreatedAt }) {
      const postsByTab = postsUpdated?.filter(item => item.active === !(tab))
      if (filters) {
        return postsByTab
          .filter(piece => filters?.text ? [piece.title, piece.text].some(text => text?.includes(filters.text)) : true)
          .filter(piece => filters?.date ? piece?.createdAt && filters.date === DateTime.fromSeconds(piece.createdAt.seconds).toISODate() : true)
          .filter(piece => filters?.roles?.length ? piece.userSources?.roles.some(role => filters.roles.includes(role)) : true)
          .filter(piece => filters?.teams?.length ? piece.userSources?.teamIds.some(team => filters.teams.includes(team)) : true)
          .sort(orderByCreatedAt)
      }
      return postsByTab.sort(orderByCreatedAt)
    },
  },
  async created() {
    const { organizationId, projectId } = this
    this.$store.commit('loader/show')
    if (!this.posts.length) await this.$store.dispatch('post/bindPosts', { organizationId, projectId })
    await this.$store.dispatch('post/unbind')
    this.isDataLoaded = true
    this.$store.commit('loader/hide')
    if (this.$route.meta.scrollYPosition) this.$nextTick(() => window.scrollTo(0, this.$route.meta.scrollYPosition))
  },
  mounted() {
    this.postsBackup = this.posts
  },
  methods: {
    orderByCreatedAt: (a, b) => b?.createdAt?.toDate() - a?.createdAt?.toDate(),
    checkPublish(data) {
      this.postsUpdated = data
    },
    publishSelectedPosts() {
      const { organizationId, projectId, posts, postsBackup } = this
      this.$store.commit('loader/show')
      Promise.all(posts.map(async data => {
        const backup = postsBackup.find(post => post.id === data.id)
        if (backup.published !== data.published) {
          await this.$store.dispatch('post/publish', { organizationId, projectId, data })
        }
        return true
      })).then(() => {
        this.postsBackup = this.posts
        this.$store.commit('loader/hide')
        this.$store.dispatch('alert/openAlertBox', ['alertSuccess', 'Success'])
        this.publish = false
      })
    },
    publishPost(data) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('post/publish', { organizationId, projectId, data }))
    },
    duplicatePost(data) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('post/createClone', { organizationId, projectId, data }))
    },
    archivePost(data) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('post/archive', { organizationId, projectId, data }))
    },
    unarchivePost(data) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('post/unarchive', { organizationId, projectId, data }))
    },
    deletePost(data) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('post/delete', { organizationId, projectId, data }))
    },
  },
}
</script>
